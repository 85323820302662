import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';

const PrivacyPolicy = (props) => {
  const privacyPolicyData = useStaticQuery(graphql`
    query PrivacyPolicy {
      privacyPolicyJson {
        policy
        title
      }
    }
  `);

  const { title, policy } = privacyPolicyData.privacyPolicyJson;
  const { sectionTitleStyle } = props;
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} title={title} />
            <ReactMarkdown source={policy} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  sectionTitleStyle: {
    mb: '60px',
    align: 'left',
    responsive: {
      small: {
        mb: '47px',
      },
    },
  },
};

export default PrivacyPolicy;
