import React from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import PrivacyPolicy from 'containers/privacy-policy';

const IndexPrivacyPolicy = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Privacy Policy" />
      <Header fixedDisplayVarient={true} />
      <main className="site-wrapper-reveal">
        <PrivacyPolicy />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPrivacyPolicy;
